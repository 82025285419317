<template>
  <div>
    <a-spin :spinning="formState.loader" class size="large">
      <sdPageHeader :title="id ? 'Edit User' : 'Add User'">
        <template v-slot:buttons>
          <a-row>
            <a-col>
              <a-form>
                <a-form-item ref="active" label="In-Active" name="active">
                  <a-switch v-model:checked="formState.deleted"></a-switch>
                </a-form-item>
              </a-form>
            </a-col>&#160;&#160;&#160;
            <a-col>
              <a-form>
                <a-form-item ref label="Pro-User" name>
                  <a-switch v-model:checked="formState.ProUser"></a-switch>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </template>
      </sdPageHeader>
      <Main>
        <a-form
          :model="formState"
          name="basic"
          :rules="rules"
          autocomplete="off"
          @finish="addCrewData()"
          @finishFailed="onFinishFailed"
        >
          <a-row :gutter="25">
            <a-col :xs="24">
              <FormValidationWrap>
                <VerticalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="firstName" name="firstName" label="Enter First Name">
                        <a-input v-model:value="formState.firstName" placeholder="Eg. Doe" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="lastName" name="lastName" label="Last Name">
                        <a-input v-model:value="formState.lastName" placeholder="enter Lastname" />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="username" name="username" label="Username">
                        <a-input
                          v-model:value="formState.username"
                          placeholder="username not readable"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="email" name="email" label="email">
                        <a-input
                          v-model:value="formState.email"
                          placeholder="Email not readable"
                          disabled
                        />
                      </a-form-item>
                    </a-col>

                    <a-col :md="8" :xs="24">
                      <a-form-item ref="createdAt" name="createdAt" label="createdAt">
                        <a-input v-model:value="formState.createdAt" disabled />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item ref="object id" name="object id" label="object id">
                        <a-input v-model:value="formState.id" placeholder="No id" disabled />
                      </a-form-item>
                    </a-col>

                    <a-col :md="8" :xs="24">
                      <a-form-item ref="Airline" name="Airline" label="Airline">
                        <a-input
                          v-model:value="formState.Airline"
                          placeholder="No airlines"
                          disabled
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :xs="24">
                      <a-form-item
                        ref="subscriptionExpiryDate"
                        name="subscriptionExpiryDate"
                        label="subscriptionExpiryDate"
                      >
                        <a-date-picker v-model:value="formState.subscriptionExpiryDate" />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </VerticalFormStyleWrap>
              </FormValidationWrap>
            </a-col>

            <a-col :xs="24">
              <FormValidationWrap>
                <VerticalFormStyleWrap>
                  <a-row :gutter="30">
                    <a-col :md="8" :xs="24"></a-col>
                    <a-col :md="8" :xs="24"></a-col>
                    <a-col :md="8" :xs="24"></a-col>
                  </a-row>
                </VerticalFormStyleWrap>
              </FormValidationWrap>
            </a-col>

            <a-col
              :lg="{ span: 4, offset: 20 }"
              :md="{ span: 6, offset: 18 }"
              :xs="{ span: 24, offset: 0 }"
              :sm="{ span: 6, offset: 18 }"
            >
              <sdCards>
                <sdButton type="primary" html-type="submit">
                  {{
                  id ? "Update User" : "Add User"
                  }}
                </sdButton>
              </sdCards>
            </a-col>
          </a-row>
        </a-form>
      </Main>
    </a-spin>
  </div>
</template>

<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap
} from "../forms/overview/Style";
import { Main } from "../styled";
import Parse from "parse";
import countriesData from "../../helper/countryData.json";
import { onMounted } from "vue";

import { defineComponent, reactive } from "vue";
import PropTypes from "vue-types";
import { message } from "ant-design-vue";
import User from "../../server/user.js";
import { useRoute } from "vue-router";
import moment from "moment"
export default defineComponent({
  name: "Form",
  props: {
    id: {
      required: false,
      type: PropTypes.String
    }
  },
  components: {
    Main,
    FormValidationWrap,
    VerticalFormStyleWrap
  },
  setup() {
    const router = useRoute();
    const onFinish = values => {
      console.log("Success:", values);
    };

    const onFinishFailed = errorInfo => {
      console.log("Failed:", errorInfo);
    };

    const addCrewData = async () => {
      // console.log("user is updating");
      const currentUser = Parse.User.current();

      if (currentUser) {
        formState.loader = true;

        var query = new Parse.Query("_User");
        query.get(formState.id).then(async obj => {
          obj.set("firstName", formState.firstName);
          obj.set("lastName", formState.lastName);
          obj.set("username", formState.username);
          obj.set("email", formState.email);
          obj.set("password", formState.password);
          obj.set("deleted", formState.deleted);
          // console.log(formState.ProUser)
          if (formState.ProUser===true) {
          // console.log(formState.ProUser)

            await obj.set("Subscription", 'on');
           await  obj.set("plan", 'pro');
           await obj.set("ProUser",formState.ProUser)
          }if(formState.ProUser===false){
          // console.log(formState.ProUser)

           await  obj.set("Subscription", '');
            await obj.set("plan", '');
           await obj.set("ProUser",formState.ProUser)

            
          }
          obj.set("Airline", formState.Airline);
          obj.set("planExpiryDate", new Date(formState.subscriptionExpiryDate));
          if (formState.ProUser === "pro") {
            obj.set("Subscription", formState.Airline);
          }

          await obj.save().then(
            () => {
              // console.log(res);
              // console.log(res.get('plan'))
              // console.log(res.get('createdAt'))

              // console.log("user is updated");

              message.success("User is Updated");
              formState.loader = false;
            },
            error => {
              message.error("user is not updated ");

              console.log(error);
              formState.loader = false;
            }
          );
        });
      }

      // console.log("date", formState.subscriptionExpiryDate);
    };
    const data1 = Object.keys(countriesData);

    onMounted(() => {
      if (formState.id) {
        User.getPointer(formState.id).then(
          obj => {
            formState.firstName = obj.get("firstName");
            formState.lastName = obj.get("lastName");
            formState.username = obj.get("username");
            formState.email = obj.get("email");
            formState.password = obj.get("password");
            formState.deleted = obj.get("deleted");
            // formState.ProUser = obj.get("ProUser");
            formState.Airline = obj.get("Airline");
            formState.subscriptionExpiryDate = String(
              obj.get("planExpiryDate")
            );
            if(obj.get("planExpiryDate")){
              formState.subscriptionExpiryDate = String(
              obj.get("planExpiryDate")
            );
            }else{
              formState.subscriptionExpiryDate=null
            }
            formState.ProUser = obj.get("plan") === "pro" ? true : false;

            formState.createdAt = moment(obj.get("createdAt")).format(
              "MMMM Do YYYY"
            );
          },

          error => {
            console.log(error);
          }
        );
      }
    });

    const handleButtonClick = e => {
      console.log(e);
    };

    const formState = reactive({
      layout: "vertical",
      id: router.params.id,
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      createdAt: "",
      password: "",
      ProUser: false,
      Airline: "",
      subscriptionExpiryDate: null,
      realmId: "",
      deleted: false,
      loader: false
    });

    const rules = {
      firstName: [
        {
          required: true,
          message: "Please input First name",
          trigger: "blur"
        }
      ],
      lastName: [
        {
          required: true,
          message: "Please input Last name",
          trigger: "blur"
        }
      ],
      username: [
        {
          required: true,
          message: "Please input username",
          trigger: "blur"
        }
      ],
      email: [
        {
          // required: true,
          message: "Please input email",
          trigger: "blur"
        }
      ],
      email_verified: [
        {
          // required: true,
          message: "Please input emailvarified",
          trigger: "blur"
        }
      ],
      password: [
        {
          // required: true,
          message: "Please input password",
          trigger: "blur"
        }
      ]
    };

    const onSubmit = () => {
      console.log("hello");
    };

    return {
      formState,
      rules,
      onSubmit,
      handleButtonClick,
      addCrewData,
      onFinish,
      onFinishFailed,
      data1,
      countriesData
    };
  }
});
</script>
<style>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
